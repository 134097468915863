import React from 'react';
import {BrowserRouter, Switch} from 'react-router-dom';
import withLayout from '../components/layout/Layout';
import {allFlattenRoutes as routes} from './index';

const Routes = () => {
    return (
      <BrowserRouter>
        <Switch>
          {routes.map((route, index) => {
            return (
              !route.children && (
                <route.route
                  key={index}
                  path={route.path}
                  roles={route.roles}
                  exact={route.exact}
                  component={withLayout((props) => {
                    document.title = route.name;
                    return <route.component {...props} cache_group={route.cacheGroup} />
                  })}/>
              )
            )
          })}
        </Switch>
      </BrowserRouter>)
};

export default Routes;