import HttpCacheAdapter from "./HttpCacheAdapter";

class RequestJobSiteFeed extends HttpCacheAdapter  {
    getKeywords() {
        return this.authGet(`/jobsite_feeds`);
    }

    addKeyword(keyword) {
        return this.authRequest(`/jobsite_feeds`, {keyword});
    }

    getJobsiteFeed(jobSiteType, keyword) {
        return this.authGet(`/jobsite_feeds/search_result?keyword=${keyword}&job_site_type=${jobSiteType}`);
    }
}

export default RequestJobSiteFeed;
