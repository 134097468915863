import React from 'react';
import Routes from './routes/Routes';

import './assets/scss/DefaultTheme.scss';

class App extends React.Component {
  render() {
    return <Routes/>
  }
}

export default App;
