import {jobkoreaCSS} from '../jobsiteCSS';

class JobkoreaParser {
    convertToDataURI(htmlContent) {
        const div = document.createElement("div");
        div.classList.add("tplList");
        div.classList.add("tplLppList");
        div.innerHTML = htmlContent;

        this.convertPath(div);
        this.removeContents(div, "div.tplList.tplLppList tr", 18);

        const content = div.querySelector("div.tplList.tplLppList").outerHTML;
        return `data:text/html;charset=utf-8,${encodeURIComponent(jobkoreaCSS + content)}`;
    }

    removeContents(selector, query, removeStartIndex) {
        const trElements = selector.querySelectorAll(query);
        const removeTargets = [];
        for (let i = removeStartIndex; i < trElements.length; i++) {
            removeTargets.push(trElements[i]);
        }
        removeTargets.forEach(e => {
           e.parentNode.removeChild(e);
        });
    }

    convertPath(selector) {
        selector.querySelectorAll("a").forEach(aElement => {
            if (aElement.pathname.indexOf("/Corp/Person/Find/Resume") !== -1) {
                aElement.href = "https://www.jobkorea.co.kr" + aElement.pathname + aElement.search;
            }
        });
    }
}

export default JobkoreaParser;
