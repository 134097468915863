import {combineReducers} from 'redux';
import {TOGGLE_MODAL, UPDATE_CACHE} from "../actions";

const modalInitialState = {
    is_open: false,
    data: {},
};

function toggleModal(state=modalInitialState, action) {
    if (action.type === TOGGLE_MODAL) {
        return {is_open: action.is_open, data: action.data};
    }
    return state;
}

function updateCache(state={}, action) {
    if (action.type === UPDATE_CACHE) {
        return {data: action.data};
    }
    return state;
}

export default combineReducers ({
    toggleModal,
    updateCache,
});
