import React from "react";
import { Container, Row, Col, Card, CardBody, Label, FormGroup, Button, Input, Form } from "reactstrap";
import logo from "../assets/images/logo.png"
import RequestAuth from "../services/RequestAuth";
import { setAccessToken } from "../services/AuthService"

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
        };
        this.requestAuth = new RequestAuth();
    }

    handleInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    handleClickLogin() {
        this.requestAuth.auth(this.state.email, this.state.password).then((result) => {
            setAccessToken(result.data.token);
            window.location.replace("/dashboard");
        }).catch((error) => {
            alert("로그인 실패:" + error);
        })
    }

    handleKeyDown(event) {
        if (event.keyCode === 13) {
            this.handleClickLogin();
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="account-pages mt-5 mb-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={5}>
                                <Card>
                                    <div className="card-header pt-4 pb-4 text-center bg-primary">
                                        <span>
                                            <img src={logo} alt="" height="49" />
                                        </span>
                                    </div>

                                    <CardBody className="p-4 position-relative">
                                        <div className="text-center w-75 m-auto">
                                            <h4 className="text-dark-50 text-center mt-0 font-weight-bold">
                                                로오그인
                                            </h4>
                                            <p className="text-muted mb-4">
                                                커리어 엔진 DB 시스템
                                            </p>
                                        </div>

                                        <Form>
                                            <FormGroup>
                                                <Label for="password">이메일</Label>
                                                <Input
                                                    name="email"
                                                    label="이메일"
                                                    placeholder="이메일 주소"
                                                    value={this.state.email}
                                                    onChange={this.handleInputChange.bind(this)}
                                                    required />
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="password">비밀번호</Label>
                                                <Input
                                                    type="password"
                                                    name="password"
                                                    id="password"
                                                    placeholder="비밀번호 (6자리 이상)"
                                                    onChange={this.handleInputChange.bind(this)}
                                                    onKeyDown={this.handleKeyDown.bind(this)}
                                                    value={this.state.password}
                                                    required
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <Button color="success" onClick={this.handleClickLogin.bind(this)}>
                                                    로그인
                                                </Button>
                                            </FormGroup>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default Login;