import env from "../config";
import axios from "axios";
import { cacheAdapterEnhancer } from "axios-extensions";
import { getAccessToken, deleteAccessToken } from "./AuthService";

class HttpCacheAdapter {
  constructor(props) {
    this.props = props;
    this.endpoint = env["api_url"];

    this.http = axios.create({
      baseURL: this.endpoint,
      headers: { "Cache-Control": "no-cache" },
      adapter: cacheAdapterEnhancer(axios.defaults.adapter, {
        enabledByDefault: false,
      }),
    });

    this.config = {
      headers: {
        authorization: getAccessToken(),
      },
    };
  }

  authGet(uri, params = null) {
    if (params) {
      this.config.params = params;
    }

    return axios.get(this.endpoint + uri, this.config).catch((error) => {
      if (!error.response) {
        return Promise.reject(error);
      }
      if (error.response.status === 401) {
        this.onUnAuth();
      } else {
        return Promise.reject(error);
      }
    });
  }

  authRequest(uri, data, method = "POST") {
    /** @type {Promise} request */
    if (this.config.params) {
      this.config.params = null;
    }

    let request;
    if (method === "POST") {
      request = axios.post(this.endpoint + uri, data, this.config);
    } else if (method === "PUT") {
      request = axios.put(this.endpoint + uri, data, this.config);
    } else if (method === "PATCH") {
      request = axios.patch(this.endpoint + uri, data, this.config);
    } else if (method === "DELETE") {
      request = axios.delete(this.endpoint + uri, this.config);
    }
    return request.catch((error) => {
      if (error.response.status === 401) {
        this.onUnAuth();
        return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    });
  }

  authDelete(uri, data = {}) {
    this.config.data = data;
    return axios.delete(this.endpoint + uri, this.config).catch((error) => {
      if (error.response.status === 401) {
        this.onUnAuth();
        return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    });
  }

  onUnAuth() {
    deleteAccessToken();
    if (this.props) {
      this.props.history.push("/login");
    }
  }
}

export default HttpCacheAdapter;
