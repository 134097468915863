import React from "react";
import { Route, Redirect } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import CacheGroup from "../enums/CacheGroup";
import JobSiteFeed from "../pages/feed/JobSiteFeed";

const Home = React.lazy(() => import("../pages/Home"));
const Login = React.lazy(() => import("../pages/Login"));
const Logout = React.lazy(() => import("../pages/Logout"));
const Candidates = React.lazy(() => import("../pages/candidates/Candidates"));
const CandidateForm = React.lazy(() =>
  import("../pages/candidates/CandidateForm")
);
const CandidateDetail = React.lazy(() =>
  import("../pages/candidates/CandidateDetail")
);
const Projects = React.lazy(() => import("../pages/projects/Projects"));
const ProjectForm = React.lazy(() => import("../pages/projects/ProjectForm"));
const ProjectDetail = React.lazy(() =>
  import("../pages/projects/ProjectDetail")
);
const ProjectHistories = React.lazy(() =>
  import("../pages/histories/ProjectHistories")
);
const ProjectHistoryFilterList = React.lazy(() =>
  import("../pages/histories/ProjectHistoryFilterList")
);

// root routes
const rootRoute = {
  path: "/",
  routeOnly: true,
  exact: true,
  component: () => <Redirect to="/jobsite_feed" />,
  route: PrivateRoute,
};

// auth
const authRoutes = {
  path: "/login",
  name: "로그인",
  routeOnly: true,
  children: [
    {
      path: "/login",
      name: "로오그인",
      component: Login,
      route: Route,
    },
    {
      path: "/logout",
      name: "로그아웃",
      component: Logout,
      route: Route,
    },
  ],
};

// dashboards
const dashboardRoutes = {
  path: "/dashboard",
  name: "상황판",
  icon: "dripicons-meter",
  header: "Navigation",
  component: Home,
  route: PrivateRoute,
  children: [
    {
      path: "/dashboard",
      name: "메인 대시보드",
      component: Home,
      route: Route,
    },
    {
      path: "/jobsite_feed",
      name: "잡사이트 피드",
      component: JobSiteFeed,
      route: Route,
    },
  ],
};

const candidateRoutes = {
  path: "/candidates",
  name: "후보자",
  icon: "dripicons-user",
  children: [
    {
      path: "/candidates",
      name: "후보자 목록",
      cacheGroup: CacheGroup.candidates,
      component: Candidates,
      route: PrivateRoute,
    },
    {
      path: "/candidate/form",
      name: "후보자 등록",
      exact: true,
      component: CandidateForm,
      route: PrivateRoute,
    },
    {
      path: "/candidate/:id/form",
      name: "후보자 편집",
      routeOnly: true,
      component: CandidateForm,
      route: PrivateRoute,
    },
    {
      path: "/candidate/:id",
      name: "후보자 상세",
      exact: true,
      routeOnly: true,
      component: CandidateDetail,
      route: PrivateRoute,
    },
  ],
};

const projectRoutes = {
  path: "/projects",
  name: "프로젝트",
  icon: "dripicons-view-list-large",
  children: [
    {
      path: "/projects",
      name: "프로젝트",
      cacheGroup: CacheGroup.projects,
      component: Projects,
      route: PrivateRoute,
    },
    {
      path: "/project/form",
      name: "프로젝트 등록",
      exact: true,
      component: ProjectForm,
      route: PrivateRoute,
    },
    {
      path: "/project/:id/form",
      name: "프로젝트 편집",
      routeOnly: true,
      component: ProjectForm,
      route: PrivateRoute,
    },
    {
      path: "/project/:id",
      name: "프로젝트 상세",
      exact: true,
      routeOnly: true,
      component: ProjectDetail,
      route: PrivateRoute,
    },
  ],
};

const projectHistoryRoutes = {
  path: "/histories",
  name: "진행상황",
  icon: "dripicons-thumbs-up",
  children: [
    {
      path: "/histories",
      name: "칸반",
      cacheGroup: CacheGroup.projects,
      component: ProjectHistories,
      route: PrivateRoute,
    },
    {
      path: "/projectFilteredHistories",
      name: "목록",
      cacheGroup: CacheGroup.projects,
      component: ProjectHistoryFilterList,
      route: PrivateRoute,
    },
  ],
};

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
  let flatRoutes = [];

  routes = routes || [];
  routes.forEach((item) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

const allRoutes = [
  authRoutes,
  rootRoute,
  dashboardRoutes,
  candidateRoutes,
  projectRoutes,
  projectHistoryRoutes,
];

const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, allFlattenRoutes };
