import axios from 'axios';
import env from '../config';
import {getAccessToken} from "./AuthService";

class RequestAuth {
    constructor() {
        this.endpoint = env['api_url'];
    }

    auth(email, password) {
        return axios.post(this.endpoint + "/access_tokens", {email, password});
    }

    refreshToken(token) {
        const config = {
            headers: {
                authorization: getAccessToken(),
            }
        };
        return axios.patch(this.endpoint + `/access_tokens`, {token}, config);
    }
}

export default RequestAuth;