// @flow
import React, { Component, Suspense } from 'react';
import { Container } from 'reactstrap';

const LeftSidebar = React.lazy(() => import("./LeftSidebar"));
const TopBar = React.lazy(() => import("../layout/TopBar"));
//const Footer = React.lazy(() => import('../components/Footer'));

// loading
const emptyLoading = () => <div />;
const loading = () => <div className="text-center" />;

class VerticalLayout extends Component {
    constructor(props) {
        super(props);
        this.openLeftMenu = this.openLeftMenu.bind(this);
    }

    /**
     * Opens the left menu - mobile
     */
    openLeftMenu = () => {
        if (document.body) document.body.classList.add('sidebar-enable');
    };

    render() {
        // get the child view which we would like to render
        const children = this.props.children || null;

        return (
            <div className="app">
                <div className="wrapper">
                    <Suspense fallback={emptyLoading()}>
                        {<LeftSidebar
                            isCondensed={true}
                            {...this.props}
                        />}
                    </Suspense>

                    <div className="content-page">
                        <div className="content">
                            <Suspense fallback={emptyLoading()}>
                                {<TopBar openLeftMenuCallBack={() => {}} {...this.props} />}
                            </Suspense>

                            <Container fluid>
                                <Suspense fallback={loading()}>{children}</Suspense>
                            </Container>
                        </div>

                        <Suspense fallback={emptyLoading()}>
                            {/*<Footer {...this.props} />*/}
                        </Suspense>
                    </div>
                </div>
            </div>
        );
    }
}

export default VerticalLayout;