import React, {useState} from "react";
import { Link } from 'react-router-dom';
import { Card, CardBody, Collapse } from 'reactstrap';
import classNames from 'classnames';

const FeedSection = ({ jobsiteType, title, request, parser }) => {
    const [content, setContent] = useState("");
    const [collapse, setCollapse] = useState(false);
    const toggle = () => {
        setCollapse(!collapse);
        if (collapse === false) {
            request.getJobsiteFeed(jobsiteType, title).then(result => {
                const htmlContent = parser.convertToDataURI(result.data.data);
                setContent(htmlContent);
            });
        }
    };

    return (
        <React.Fragment>
            <Link className="text-dark" to="#" onClick={toggle}>
                <h3 className="m-0 pb-2">
                    <i
                        className={classNames(
                            'uil',
                            { 'uil-angle-down': collapse, 'uil-angle-right': !collapse },
                            'font-18'
                        )} />
                    {jobsiteType} : {title}
                </h3>
            </Link>

            <Collapse isOpen={collapse}>
                <Card className="mb-0">
                    <CardBody className="pb-1 pt-2">
                        <iframe title={title}
                                src={content}
                                width="100%" height="800px">
                        </iframe>
                    </CardBody>
                </Card>
            </Collapse>
        </React.Fragment>
    );
};

export default FeedSection;
