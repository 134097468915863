// handle auth and authorization
import {Route, Redirect} from "react-router-dom";
import React from "react";
import jwtDecode from "jwt-decode";
import moment from "moment";
import { isUserAuthenticated, getAccessToken, refreshToke } from "../services/AuthService";

const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            if (!isUserAuthenticated()) {
                return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
            }

            const token = jwtDecode(getAccessToken());
            const tokenExpireAt = moment(token.expired_at);
            const duration = moment.duration(tokenExpireAt.diff(new Date()));
            if (duration.asMinutes() < 100) {
                refreshToke(getAccessToken());
            }

            /*const loggedInUser = getLoggedInUser();
            // check if route is restricted by role
            if (roles && roles.indexOf(loggedInUser.role) === -1) {
                // role not authorised so redirect to home page
                return <Redirect to={{ pathname: '/' }} />;
            }*/
            return <Component {...props} consultant_id={token.consultant_id} />;
        }}
    />
);

export default PrivateRoute;