export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const UPDATE_CACHE = "UPDATE_CACHE";

export function toggleModal(is_open, data) {
    return { type: TOGGLE_MODAL, is_open, data };
}

export function updateCache(data) {
    return { type: UPDATE_CACHE, data }
}
