import HttpCacheAdapter from "./HttpCacheAdapter";

class RequestPushSubscription extends HttpCacheAdapter {
    subscribe(subscription) {
        return this.authRequest("/push_subscriptions", {
            endpoint: subscription.endpoint,
            p256dh: subscription.keys.p256dh,
            auth: subscription.keys.auth,
        });
    }
}

export default RequestPushSubscription;
