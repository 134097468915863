import React from "react";
import { Row, Col, Input, Button, InputGroup } from 'reactstrap';
import FeedSection from "./components/FeedSection";
import RequestJobSiteFeed from "../../services/RequestJobSiteFeed";
import JobkoreaParser from "./services/JobkoreaParser";
import SaraminParser from "./services/SaraminParser";

class JobSiteFeed extends React.Component {
    static SITE_JOBKOREA = "jobkorea";
    static SITE_SARAMIN = "saramin";

    constructor(props) {
        super(props);

        this.feedRequest = new RequestJobSiteFeed(null);

        this.jobkoreaParser = new JobkoreaParser();
        this.saraminParser= new SaraminParser();

        this.keywords = [];
        this.state = {
            keyword: "",
            keywords: this.keywords,
        }
    }

    getSiteParser(type) {
        if (type === JobSiteFeed.SITE_JOBKOREA) {
            return this.jobkoreaParser;
        }

        if (type === JobSiteFeed.SITE_SARAMIN) {
            return this.saraminParser;
        }
    }

    handleChangeKeyword(event) {
        this.setState({keyword: event.target.value});
    }

    handleKeyDown(event) {
        if (event.keyCode === 13) {
            this.addKeyword();
        }
    }

    componentDidMount() {
        this.feedRequest.getKeywords().then((result) => {
            this.keywords = result.data.data;
            this.setState({keywords: this.keywords});
        }).catch(() => alert("피드 목록을 가져오는데 문제가 있습니다."));
    }

    addKeyword() {
        const keyword = this.state.keyword;
        this.feedRequest.addKeyword(keyword).then(() => {
            this.keywords.push(keyword);
            this.setState({keywords: this.keywords});
        }).catch(() => alert("키워드를 추가하는데 오류가 있습니다."));
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <h4>잡사이트 인재 목록을 한눈에</h4>
                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>
                        <InputGroup>
                            <Input onChange={this.handleChangeKeyword.bind(this)}
                                   onKeyDown={this.handleKeyDown.bind(this)} />
                            <Button onClick={() => this.addKeyword()}>
                                키워드 추가
                            </Button>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xl={8}>
                        <div className="mt-2">
                            {this.state.keywords.map(keyword => {
                                return (
                                    <React.Fragment key={keyword}>
                                        <FeedSection key={"jobkorea" + keyword}
                                                     jobsiteType={JobSiteFeed.SITE_JOBKOREA}
                                                     title={keyword}
                                                     request={this.feedRequest}
                                                     parser={this.getSiteParser(JobSiteFeed.SITE_JOBKOREA)} />

                                        <FeedSection key={"saramin" + keyword}
                                                     jobsiteType={JobSiteFeed.SITE_SARAMIN}
                                                     title={keyword}
                                                     request={this.feedRequest}
                                                     parser={this.getSiteParser(JobSiteFeed.SITE_SARAMIN)} />
                                    </React.Fragment>
                                )
                            })}
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default JobSiteFeed;
