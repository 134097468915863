import {saraminCSS} from '../jobsiteCSS';

class SaraminParser {
    convertToDataURI(htmlContent) {
        const div = document.createElement("div");
        div.innerHTML = this.wrapContent(htmlContent);

        this.convertPath(div);

        return `data:text/html;charset=utf-8,${encodeURIComponent(saraminCSS + div.outerHTML)}`;
    }

    convertPath(selector) {
        selector.querySelectorAll("a").forEach(aElement => {
            if (aElement.pathname.indexOf("/zf_user/mandb/view") !== -1) {
                aElement.href = "https://www.saramin.co.kr" + aElement.pathname + aElement.search;
            }
        });
    }

    wrapContent(htmlContent) {
        return `
            <div class="tblWrap talent_list list_type1">
            <table>
            <caption>인재검색 리스트</caption>
            <colgroup>
                <col width="46">
                <col width="218">
                <col width="549">
                <col width="147">
            </colgroup>
            <thead>
            <tr>
                <th scope="col" className="scrap"><span className="blind">관심인재</span></th>
                <th scope="col" className="name">이름</th>
                <th scope="col" className="contents">이력서 요약</th>
                <th scope="col" className="update" id="date_type">업데이트일</th>
            </tr>
            </thead>
            <tbody id="resumeList">
            ${htmlContent}
            </tbody>
            </table>
            </div>`
    }
}

export default SaraminParser;
