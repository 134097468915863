import React, {Component, Suspense} from 'react';
import VerticalLayout from './VerticalLayout';
import isUserAuthenticated from "../../services/AuthService";
import RequestPushSubscription from "../../services/RequestPushSubscription";
import Login from "../../pages/Login";

const loading = () => <div />;

// TODO : 푸시 로직 분리
const withLayout = (WrappedComponent) => {
    return class extends Component {
        registerWorker() {
            if ("serviceWorker" in navigator && "PushManager" in window) {
                const swUrl = `${process.env.PUBLIC_URL}/custom-worker.js`;

                fetch(swUrl).then(() => {
                    navigator.serviceWorker.register(swUrl).then((registration) => {
                        if (this.isValidCacheSubscription() === false) {
                            this.subscribePush(registration);
                        }
                    }).catch(() => {
                        alert("브라우저 알림 설정에 문제가 있습니다.");
                    });
                });
            } else {
                alert("브라우저 알람(푸시) 허용 여부를 확인해 주세요.");
            }
        }

        subscribePush(swRegistration) {
            swRegistration.pushManager.getSubscription().then((subscription) => {
                if (!subscription) {
                    //const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey);
                    swRegistration.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: "BMaL12uYPS8QUbGNT0PZcMzKOdGZuIUl5Jd6jijc8zM_9dAf6cVgci5jtfTyBsHmxNdfeqHE_xRqOoHC06R1nPg"
                    }).then(subscription => {
                        this.updateSubscriptionOnServer(subscription);
                    });
                } else {
                    this.updateSubscriptionOnServer(subscription);
                }
                localStorage.setItem("push_subscription", (new Date().getTime() + 3600000).toString());
            });
        }

        isValidCacheSubscription() {
            const cachedSubscription = localStorage.getItem("push_subscription");
            if (!cachedSubscription || isNaN(parseInt(cachedSubscription))) {
                return false;
            }
            const expiredAt = new Date(parseInt(cachedSubscription));
            return expiredAt >= (new Date().getTime());
        }

        updateSubscriptionOnServer(subscription) {
            (new RequestPushSubscription(this.props)).subscribe(subscription.toJSON()).then(() => {
                console.log("subscribed");
            }).catch(() => console.log("푸시 서비스 등록 오류"));
        }

        render() {
            if (this.props.history.action === "PUSH") {
                localStorage.setItem("pageYOffset", window.pageYOffset.toString());
            }

            if (isUserAuthenticated()) {
                this.registerWorker();
            } else {
                return <Login />
            }
            return (
                <Suspense fallback={loading()}>
                    <VerticalLayout {...this.props}>
                        <WrappedComponent {...this.props} />
                    </VerticalLayout>
                </Suspense>
            );
        }
    };
};

export default withLayout;
